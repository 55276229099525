import { useState } from "react";

import { ModalList } from "../ModalList";

import {
  DropdownCounter,
  DropdownGroup,
  DropdownHeader,
  DropdownHeading,
  DropdownIcon,
} from "./DropdownList.styled";

interface IModalItem {
  title: string;
  options: string[];
  currentOptions?: [];
  isOpen?: boolean;
  onCheckboxChange: (values: string[]) => void;
}

interface IDropdownListProps {
  data: IModalItem;
}

export const DropdownList = ({ data }: IDropdownListProps) => {
  const [isDropdownOpen, setIsDropDownOpen] = useState(true);

  const handleDropdownClick = () => {
    setIsDropDownOpen(prevState => !prevState);
  };

  return (
    <DropdownGroup>
      <DropdownHeader>
        <DropdownIcon
          name="chevrondown"
          size={12}
          onClick={handleDropdownClick}
          isOpen={isDropdownOpen}
        />
        <DropdownHeading>{data.title}</DropdownHeading>
        <DropdownCounter>{data?.options?.length}</DropdownCounter>
      </DropdownHeader>
      {isDropdownOpen && (
        <ModalList
          items={data?.options}
          currentOptions={data?.currentOptions}
          isOpen={data?.isOpen}
          isCheckboxMode={true}
          onCheckboxChange={data.onCheckboxChange}
        />
      )}
    </DropdownGroup>
  );
};
