import styled from "@emotion/styled";

interface IStyledGroupCardsProps {
  isActive?: boolean;
}

export const StyledGroupCards = styled.div<IStyledGroupCardsProps>`
  max-height: 264px;
  width: 1340px;
  padding-bottom: ${props => (props.isActive ? "0" : "0")};
  padding-right: 1rem;
  margin-bottom: ${props => (props.isActive ? "1.25rem" : "0")};
`;

export const GroupCardsList = styled.div`
  width: 100%;
  padding-bottom: 0.2em;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 6px;
    min-width: 10px;
    width: 464px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;
