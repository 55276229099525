import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setCurrentFilters,
  setCurrentFrequentlyFilters,
  setEventGroup,
} from "metabase/query_builder/actions";
import { getCurrentEvents } from "metabase/query_builder/selectors";
import { Icon, Modal, Title } from "metabase/ui";

import { CancelButton } from "../CancelButton";
import { ModalDropdownList } from "../ModalDropdownList";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
} from "./GroupModal.styled";

import { useGetDatasetQuery } from "../../api/dashboardApi";
import { NativeDatasetQuery } from "metabase-types/api";
import { mapApiData } from "../../utils/mapApiData";
import { usePostEventsNativeQueryMutation } from "../../api/dashboardApi";

interface IGroupData {
  title: string;
  options: string[];
}

interface IEventsEndpoints {
  events: string;
  properties: string;
  frequentlyProperties: string;
  propertyValues: string;
}

interface IGroupModalProps {
  data: IGroupData;
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  cardId: string;
  endpoints: IEventsEndpoints;
}

export const GroupModal = ({
  data,
  isOpen,
  onClose,
  additionalCancelOption,
  cardId,
  endpoints,
}: IGroupModalProps) => {
  const [postNativeQuery] = usePostEventsNativeQueryMutation();
  const dispatch = useDispatch();
  const currentEvents = useSelector(getCurrentEvents);

  const currentGroupFilters = currentEvents?.find(item => item.id === cardId)
    ?.groupList?.properties;
  const currentGroupFrequentlyFilters = currentEvents?.find(
    item => item.id === cardId,
  )?.groupList?.frequentlyUsedProperties;

  const [searchValue, setSearchValue] = useState("");
  const [frequentlyPropertiesState, setFrequentlyPropertiesState] = useState<
    string[]
  >([]);
  const [eventpropertiesState, eventPropertiesState] = useState<string[]>([]);

  const propertiesValues = currentEvents
    ?.find(item => item.id === cardId)
    ?.groupFiltersList?.map(item => item?.display);
  const frequentlyUsedValues = currentEvents
    ?.find(item => item.id === cardId)
    ?.groupFrequentlyFiltersList?.map(item => item?.display);
  const filteredProperties = propertiesValues?.filter(item =>
    item?.toString().toLowerCase().includes(searchValue),
  );
  const filteredFrequentlyUsed = frequentlyUsedValues?.filter(item =>
    item?.toString().toLowerCase().includes(searchValue),
  );

  const handleSaveButtonClick = () => {
    const currentProperties = currentEvents
      ?.find(item => item.id === cardId)
      ?.groupFiltersList?.filter(item =>
        eventpropertiesState.includes(item.display),
      );
    const currentFrequentlyProperties = currentEvents
      ?.find(item => item.id === cardId)
      ?.groupFrequentlyFiltersList?.filter(item =>
        frequentlyPropertiesState.includes(item.display),
      );

    dispatch(
      setEventGroup({
        eventId: cardId,
        groupList: {
          properties: currentProperties,
          frequentlyUsedProperties: currentFrequentlyProperties,
        },
      }),
    );
    setSearchValue("");
    onClose();
  };

  const handleFrequentlyPropertiesChange = (values: string[]) => {
    setFrequentlyPropertiesState(values);
  };

  const handleEventPopertiesChange = (values: string[]) => {
    eventPropertiesState(values);
  };

  const fetchFiltersData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.properties}",
      "method": "post",
      "body": {
        "event_type": "${cardId}"
      },
      "headers": {}
    }`;
    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };
    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(col => col.name);
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(
        setCurrentFilters({
          eventId: cardId,
          filters: eventsData,
        }),
      );
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  const fetchFrequentlyFiltersData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.frequentlyProperties}",
      "method": "post",
      "body": {
        "event_type": "${cardId}"
      },
      "headers": {}
    }`;
    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };
    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(col => col.name);
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(
        setCurrentFrequentlyFilters({
          eventId: cardId,
          filters: eventsData,
        }),
      );
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  useEffect(() => {
    fetchFiltersData();
    fetchFrequentlyFiltersData();
  }, []);

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            {data.title}
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          <ModalDropdownList
            items={[
              {
                title: "Frequently Used Properties",
                options: filteredFrequentlyUsed,
                currentOptions: currentGroupFrequentlyFilters,
                isOpen: isOpen,
                onCheckboxChange: handleFrequentlyPropertiesChange,
              },
              {
                title: "Event Properties",
                options: filteredProperties,
                currentOptions: currentGroupFilters,
                isOpen: isOpen,
                onCheckboxChange: handleEventPopertiesChange,
              },
            ]}
          />
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton onClick={handleSaveButtonClick} />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
