import { useEffect, useState } from "react";

import CheckBox from "metabase/core/components/CheckBox";
import { color } from "metabase/lib/colors";
import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setSegmentValues,
  updateSegmentPropertyCondition,
} from "metabase/query_builder/actions";
import {
  getCurrentSegments,
  getSegmentValues,
} from "metabase/query_builder/selectors";
import { Icon, Modal, Title } from "metabase/ui";
import { NativeDatasetQuery } from "metabase-types/api";

import { useGetDatasetQuery } from "../../api/dashboardApi";
import { mapApiData } from "../../utils/mapApiData";
import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  CheckboxContainer,
  ClearButton,
  ConditionContent,
  ConditionTitle,
  CopyButton,
  DashboardModalFooter,
  DashboardTextInput,
  ModalConditionColumn,
  ModalContent,
  ModalListItem,
  ModalSelectedColumn,
  ModalWrapper,
  SelectedContent,
  SelectedGroup,
  SelectedHeader,
  SelectedTitle,
  StyledModalList,
} from "./SegmentParamsModal.styled";

interface IPropertyValue {
  value: string;
  display: string;
}

interface ISegmentsEndpoints {
  filters: string;
  propertyValues: string;
  cohorts: string;
}

interface IGroupModalProps {
  values: IPropertyValue[];
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  cardId: string;
  propertyId: string;
  propertyValues: IPropertyValue[];
  endpoints: ISegmentsEndpoints;
}

export const SegmentParamsModal = ({
  isOpen,
  onClose,
  additionalCancelOption,
  cardId,
  propertyId,
  endpoints,
}: IGroupModalProps) => {
  const payloadQuery = `{
    "path": "${endpoints.propertyValues}",
    "method": "post",
    "body": {
      "property": "${propertyId}",
      "event_type": ""
    },
    "headers": {}
  }`;
  const nativeQuery: NativeDatasetQuery = {
    native: {
      query: payloadQuery,
    },
    type: "native",
    database: 2,
  };
  const valuesData = useGetDatasetQuery(nativeQuery);
  const dispatch = useDispatch();

  const currentSegments = useSelector(getCurrentSegments);

  const currentCondition = currentSegments
    ?.find(item => item.id === cardId)
    ?.filters?.find(filter => filter.id === propertyId)?.condition;

  const currentValues = currentSegments
    ?.find(item => item.id === cardId)
    ?.filters?.find(filter => filter.id === propertyId)?.values;

  const segmentValues = useSelector(getSegmentValues);
  const formattedKeys = segmentValues?.data?.data?.cols?.map(col => col.name);
  const formattedValues = segmentValues?.data?.data?.rows;
  const eventsData = mapApiData(formattedKeys, formattedValues);
  const [propertyCondition, setPropertyCondition] = useState("=");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedProperties, setSelectedProperties] = useState<
    IPropertyValue[]
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const filteredProperties = eventsData?.filter(item =>
    item?.display?.toString().toLowerCase().includes(searchValue),
  );

  const conditionOptions = [
    {
      display: "= (is)",
      value: "=",
    },
    {
      display: "≠ (is not)",
      value: "≠",
    },
    {
      display: "< (less)",
      value: "<",
    },
    {
      display: "≤ (less or equal)",
      value: "≤",
    },
    {
      display: "> (greater)",
      value: ">",
    },
    {
      display: "≥ (greater or equal)",
      value: "≥",
    },
  ];

  const handleChange = (value: string) => {
    setSelectedOptions(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(item => item !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleClearClick = () => {
    setSelectedOptions([]);
  };

  const handleSelectedPropertiesChange = (values: string[]) => {
    setSelectedProperties(values);
  };

  const handleSaveClick = () => {
    dispatch(
      updateSegmentPropertyCondition({
        cardId,
        propertyId,
        propertyCondition,
        selectedOptions,
        selectedProperties,
      }),
    );
    onClose();
  };

  const fetchTestPropertiesValues = () => {
    // dispatch(setTestSegmentsPropertiesValues(valuesData));
    dispatch(setSegmentValues(valuesData));
  };

  useEffect(() => {
    if (valuesData.data !== null) {
      fetchTestPropertiesValues();
    }
  }, [valuesData.data]);

  useEffect(() => {
    if (isOpen && currentCondition) {
      setPropertyCondition(currentCondition);
    }
  }, [isOpen, currentCondition]);

  return (
    <Modal.Root opened={isOpen} onClose={() => console.log("close")}>
      <Modal.Overlay />
      <ModalContent>
        <ModalWrapper>
          <ModalConditionColumn>
            <ConditionTitle order={2} size="1rem" lh="1rem" color="inherit">
              Сondition
            </ConditionTitle>
            <ConditionContent>
              <StyledModalList>
                {conditionOptions.map((option, index) => {
                  return (
                    <ModalListItem
                      isActive={
                        propertyCondition === option.value ? true : false
                      }
                      key={index}
                      onClick={() => setPropertyCondition(option.value)}
                    >
                      {option.display}
                    </ModalListItem>
                  );
                })}
              </StyledModalList>
            </ConditionContent>
          </ModalConditionColumn>
          <ModalSelectedColumn>
            <DashboardTextInput
              type="search"
              icon={<Icon name="search" size={16} />}
              miw={400}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={"Search…"}
            />
            {/* <SelectedContent> */}
            <SelectedGroup>
              <SelectedHeader>
                <Title order={2} size="1rem" lh="1rem" color="inherit">
                  Selected
                </Title>
                <ClearButton onClick={handleClearClick}>Clear</ClearButton>
                <CopyButton>Copy Selected</CopyButton>
              </SelectedHeader>
              {eventsData &&
                selectedProperties.map((item, index) => {
                  return (
                    <CheckboxContainer key={index}>
                      <CheckBox
                        name={item.display}
                        checked={selectedOptions.includes(item.id)}
                        onChange={() => handleChange(item.id)}
                        aria-label={item.display}
                        label={item.display}
                        uncheckedColor={color("border")}
                      />
                    </CheckboxContainer>
                  );
                })}
            </SelectedGroup>
            {/* </SelectedContent> */}
            <SelectedContent>
              <SelectedTitle order={2} size="1rem" lh="1rem" color="inherit">
                All values
              </SelectedTitle>
              <ModalCheckboxes
                onCheckboxChange={handleSelectedPropertiesChange}
                values={filteredProperties}
                currentProperties={currentValues}
                isOpen={isOpen}
              />
            </SelectedContent>
          </ModalSelectedColumn>
        </ModalWrapper>

        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton onClose={onClose} />}
          <SaveButton onClick={handleSaveClick} />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
