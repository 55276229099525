import { useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";

import { FilterModal } from "../FilterModal";
import {
  setTestProperties,
  updateEventProperties,
} from "metabase/query_builder/actions";

import { getEventProperties } from "metabase/query_builder/selectors";

import { useDispatch, useSelector } from "metabase/lib/redux";

import { Icon } from "metabase/ui";

import { CardContent } from "../CardContent/CardContent";
import { CardFooter } from "../CardFooter";
import { CardHeader } from "../CardHeader";
import { ParamsModal } from "../ParamsModal";

import { DraggableButton, StyledGroupCard } from "./GroupCard.styled";
import { GroupModal } from "../GroupModal";

interface IPropertyValue {
  value: string;
  display: string;
}

interface IEventProperty {
  id: string;
  type: string;
  value: string;
  display: string;
  values: IPropertyValue[];
  condition: string;
  selected: string[];
}

interface IGroupCard {
  id: string;
  display: string;
  value: string;
  properties: IEventProperty[];
  cardUniqueId: string;
}

interface IEventsEndpoints {
  events: string;
  properties: string;
  frequentlyProperties: string;
  propertyValues: string;
}

interface IGroupCardProps {
  item: IGroupCard;
  index: number | string;
  onCohortChange?: () => void;
  withOpacity?: boolean;
  isDragging?: boolean;
  onDelete: (cardId: string) => void;
  onPropertyDelete: (cardId: string, propertyId: string) => void;
  propertyValues: IPropertyValue[];
  endpoints: IEventsEndpoints;
  isAlphabetMode: boolean;
}

export const GroupCard = ({
  item,
  index,
  isDragging,
  onCohortChange,
  onDelete,
  onPropertyDelete,
  propertyValues,
  endpoints,
  isAlphabetMode,
}: IGroupCardProps) => {
  const dispatch = useDispatch();
  const { id, value, properties, cardUniqueId } = item;
  const propertiesList = useSelector(getEventProperties);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [isParamsModalOpen, setIsParamsModalOpen] = useState<boolean>(false);
  const [activeProperty, setActiveProperty] = useState<string>("");
  const [isCardHover, setIsCardHover] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const style = {
    transition: transition || undefined,
    transform: CSS.Transform.toString(transform),
  };

  const onParamsModalClose = () => {
    setIsParamsModalOpen(false);
    setActiveProperty("");
  };

  const onParamsModalOpen = (propertyId: string) => {
    setIsParamsModalOpen(true);
    setActiveProperty(propertyId);
  };

  const onGroupModalClose = () => {
    setIsGroupModalOpen(false);
  };

  const onGroupModalOpen = () => {
    setIsGroupModalOpen(true);
  };

  const handleCardHover = () => {
    setIsCardHover(true);
  };

  const handleCardLeave = () => {
    setIsCardHover(false);
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
  };

  const onFilterModalOpen = () => {
    setIsFilterModalOpen(true);
  };

  const onFilterModalSave = (cardId: string, properties: IEventProperty[]) => {
    dispatch(updateEventProperties({ cardId, properties }));
  };

  return (
    <>
      <StyledGroupCard
        ref={setNodeRef}
        style={style}
        withOpacity={isDragging}
        onMouseOver={handleCardHover}
        onMouseOut={handleCardLeave}
      >
        <DraggableButton
          {...attributes}
          {...listeners}
          isDragging={isDragging}
          isHover={isCardHover}
        >
          <Icon name={"draggable"} size={16} />
        </DraggableButton>
        <CardHeader
          isHover={isCardHover}
          index={index}
          onDelete={onDelete}
          cardId={item.id}
          isAlphabetMode={isAlphabetMode}
        >
          {value}
        </CardHeader>
        <CardContent
          data={properties}
          onParamsClick={onParamsModalOpen}
          onPropertyDelete={onPropertyDelete}
          cardId={id}
        ></CardContent>
        <CardFooter
          onFilterChange={onFilterModalOpen}
          onGroupChange={onGroupModalOpen}
          onCohortChange={onCohortChange}
        ></CardFooter>
      </StyledGroupCard>

      <ParamsModal
        values={properties.find(item => item.id === activeProperty)?.values}
        isOpen={isParamsModalOpen}
        onClose={onParamsModalClose}
        additionalCancelOption
        cardId={id}
        propertyId={activeProperty}
        propertyValues={propertyValues}
        endpoints={endpoints}
      />
      <FilterModal
        data={{ title: "Filter by", options: propertiesList }}
        isOpen={isFilterModalOpen}
        onClose={onFilterModalClose}
        onSave={onFilterModalSave}
        cardId={id}
        eventProperties={properties}
        endpoints={endpoints}
      />
      <GroupModal
        data={{ title: "Group by", options: ["option 1", "option 2"] }}
        isOpen={isGroupModalOpen}
        onClose={onGroupModalClose}
        cardId={id}
        endpoints={endpoints}
      />
    </>
  );
};
