import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useRef, useState } from "react";

import { useSelector } from "metabase/lib/redux";
import {
  getCurrentEvents,
  getDashboardMode,
} from "metabase/query_builder/selectors";

import { GroupCard } from "../GroupCard/GroupCard";

import { GroupCardsList, StyledGroupCards } from "./GroupCards.styled";

import { DashboardMode } from "../../types/enum";

import { getLetterFromIndex } from "../../utils/getLetterFromIndex";

interface IPropertyValue {
  value: string;
  display: string;
}

interface IEventProperty {
  id: string;
  type: string;
  value: string;
  display: string;
  values: IPropertyValue[];
  condition: string;
  selected: string[];
}

interface ICardItem {
  id: string;
  display: string;
  value: string;
  properties: IEventProperty[];
  cardUniqueId: string;
}

interface IEventsEndpoints {
  events: string;
  properties: string;
  frequentlyProperties: string;
  propertyValues: string;
}

interface IGroupCardsProps {
  onCohortChange?: () => void;
  onDelete: (cardId: string) => void;
  cardsList: ICardItem[];
  onPropertyDelete: (cardId: string, propertyId: string) => void;
  updateCards: (cardsList: ICardItem[]) => void;
  propertyValues: IPropertyValue[];
  endpoints: IEventsEndpoints;
}

export const GroupCards = ({
  onCohortChange,
  onDelete,
  cardsList,
  onPropertyDelete,
  updateCards,
  propertyValues,
  endpoints,
}: IGroupCardsProps) => {
  const dashboardMode = useSelector(getDashboardMode);
  const [items, setItems] = useState(cardsList);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const isCardsUpdate = useRef(false);

  useEffect(() => {
    setItems(cardsList);
  }, [cardsList]);

  useEffect(() => {
    if (isCardsUpdate.current) {
      updateCards(items);
      isCardsUpdate.current = false;
    }
  }, [items]);

  const getItems = (id: UniqueIdentifier): number =>
    items.findIndex(item => item.id === id);

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems(items => {
        const originalPosition = getItems(active.id);
        const newPosition = getItems(over?.id);

        return arrayMove(items, originalPosition, newPosition);
      });
      isCardsUpdate.current = true;
    }
    setActiveId(null);
  };

  const handleDragStart = (event: DragStartEvent): void => {
    setActiveId(event.active.id);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
    >
      <StyledGroupCards>
        <SortableContext items={items} strategy={horizontalListSortingStrategy}>
          <GroupCardsList>
            {items.map((item, index) => {
              const indexValue =
                dashboardMode === DashboardMode.Segmentation
                  ? getLetterFromIndex(index)
                  : index;
              return (
                <GroupCard
                  key={item.cardUniqueId}
                  item={item}
                  index={indexValue}
                  onCohortChange={onCohortChange}
                  onDelete={onDelete}
                  onPropertyDelete={onPropertyDelete}
                  propertyValues={propertyValues}
                  endpoints={endpoints}
                  isAlphabetMode={
                    dashboardMode === DashboardMode.Segmentation ? true : false
                  }
                />
              );
            })}
          </GroupCardsList>
        </SortableContext>
      </StyledGroupCards>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <GroupCard
            key={activeId}
            item={items.find(item => item.id === activeId)}
            isDragging
            onCohortChange={onCohortChange}
            onPropertyDelete={onPropertyDelete}
            propertyValues={propertyValues}
            endpoints={endpoints}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
