import { useEffect, useState } from "react";

import { Icon, Modal, Title } from "metabase/ui";

import { useDispatch, useSelector } from "metabase/lib/redux";

import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import { setCurrentEventFilters } from "metabase/query_builder/actions";
import { getCurrentEvents } from "metabase/query_builder/selectors";

import { NativeDatasetQuery } from "metabase-types/api";
import { mapApiData } from "../../utils/mapApiData";

import { usePostEventsNativeQueryMutation } from "../../api/dashboardApi";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
} from "./FilterModal.styled";

interface IEventProperty {
  id: string;
  type: string;
  value: string;
  display: string;
}

interface IFilterData {
  title: string;
  options: IEventProperty[];
}

interface IEventsEndpoints {
  events: string;
  properties: string;
  frequentlyProperties: string;
  propertyValues: string;
}

interface IFilterModalProps {
  data: IFilterData;
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  onSave: (cardId: string, properties: IEventProperty[]) => void;
  cardId?: string;
  endpoints: IEventsEndpoints;
}

export const FilterModal = ({
  data,
  isOpen,
  onClose,
  additionalCancelOption,
  onSave,
  cardId,
  endpoints,
}: IFilterModalProps) => {
  const [postNativeQuery] = usePostEventsNativeQueryMutation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);

  const currentEvents = useSelector(getCurrentEvents);

  const currentPropertiesList = currentEvents?.find(
    item => item.id === cardId,
  )?.properties;

  const filteredProperties = currentEvents
    ?.find(item => item.id === cardId)
    ?.filtersList?.filter(item =>
      item?.display?.toString().toLowerCase().includes(searchValue),
    );

  const fetchFiltersData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.properties}",
      "method": "post",
      "body": {
        "event_type": "${cardId}"
      },
      "headers": {}
    }`;
    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };
    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(col => col.name);
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(
        setCurrentEventFilters({
          eventId: cardId,
          filters: eventsData,
        }),
      );
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  useEffect(() => {
    fetchFiltersData();
  }, []);

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            {data.title}
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          <ModalCheckboxes
            onCheckboxChange={cards => setActiveFilters(cards)}
            values={filteredProperties}
            currentProperties={currentPropertiesList}
            isOpen={isOpen}
          />
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton
            onClick={() => {
              onSave(cardId, activeFilters);
              setSearchValue("");
              onClose();
            }}
          />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
