import { useEffect } from "react";
import { v4 as uuid } from "uuid";

import { useDispatch, useSelector } from "metabase/lib/redux";
import { resetDashboardData } from "metabase/query_builder/actions";
import { getDashboardMode } from "metabase/query_builder/selectors";

import { FunnelDashboard } from "../FunnelDashboard";
import { SegmentationDashboard } from "../SegmentationDashboard";
import { SessionDashboard } from "../SessionDashboard";
import { DashboardMode } from "../types/enum";
import { ISegmentItem } from "../types/interfaces";

import { removeDoubleQuotes } from "../utils/removeDoubleQuotes";

interface IQueryEditorDashboardProps {
  // Исправить типы
  updateQueryText: any;
  getQueryText: any;
  databaseId: number;
}

export const QueryEditorDashboard = ({
  updateQueryText,
  getQueryText,
  databaseId,
}: IQueryEditorDashboardProps) => {
  const dispatch = useDispatch();

  const dashboardMode = useSelector(getDashboardMode);

  const dashboards = {
    [DashboardMode.Funnel]: FunnelDashboard,
    [DashboardMode.Segmentation]: SegmentationDashboard,
    [DashboardMode.Sessions]: SessionDashboard,
  };

  const SelectedDashboard = dashboards[dashboardMode];

  const updateFunnelQuery = (
    conversionType: string,
    conversionValue: number,
    conversionOvertime: string = "week",
    eventsList: [],
    segmentsList: ISegmentItem[],
    groupList: [],
    currentEventsOrder: any,
    significanceLevel: number,
    baselineSegment: number,
    dataFieldId: number,
  ) => {
    if (!dataFieldId) {
      return;
    }

    const events = eventsList.map(item => {
      const eventGroupList = new Set([
        ...item?.groupList?.properties,
        ...item?.groupList?.frequentlyUsedProperties,
      ]);

      return {
        event_type: item.display,
        filters: item.properties.map(property => {
          return {
            prop_name: property.name,
            prop_op: property.condition,
            prop_subname: property.subname,
            prop_value: property?.values?.map(value => {
              return removeDoubleQuotes(value?.id);
            }),
          };
        }),
        group_by: Array.from(eventGroupList).map(group => {
          return {
            prop_name: group.name,
            prop_subname: group.subname,
          };
        }),
      };
    });

    const segments = segmentsList.map(segment => {
      const cohorts =
        segment.cohorts.length > 0
          ? segment.cohorts.map(cohort => {
              return {
                prop_name: "userdata_cohort",
                prop_op: "=",
                prop_value: cohort.cohortsList.map(item => Number(item.id)),
              };
            })
          : [];

      const filters =
        segment.filters.length > 0
          ? segment.filters.map(filter => {
              return {
                prop_name: filter?.name,
                prop_subname: filter?.subname,
                prop_op: filter?.condition,
                prop_value: filter?.values?.map(item =>
                  removeDoubleQuotes(item.id),
                ),
              };
            })
          : [];

      return {
        name: segment.value,
        conditions: [...cohorts, ...filters],
      };
    });

    const globalGroupList = new Set([
      ...groupList?.properties,
      ...groupList?.frequentlyUsedProperties,
    ]);

    const queryData = {
      path: conversionType,
      method: "post",
      body: {
        date: "{{date_partition}}",
        conversion_seconds: conversionValue,
        over_time: conversionOvertime,
        baseline_segment_number: baselineSegment,
        significance_level: significanceLevel,
        events: events,
        segments: segments,
        group_by: Array.from(globalGroupList).map(group => {
          return {
            prop_name: group.name,
            prop_subname: group.subname,
          };
        }),
        funnel_mode: currentEventsOrder.valueId,
      },
      headers: {},
    };

    console.log(queryData);

    const queryText = JSON.stringify(queryData, null, 4);

    const queryTemplateText = {
      type: "dimension",
      name: "date_partition",
      id: uuid(),
      "display-name": "Date",
      default: "past7days",
      "widget-type": "date/all-options",
      required: true,
      dimension: ["field", dataFieldId, null],
    };

    updateQueryText(queryText, queryTemplateText);
  };

  useEffect(() => {
    return () => {
      dispatch(resetDashboardData());
    };
  }, []);

  return (
    <>
      <SelectedDashboard
        updateQuery={updateFunnelQuery}
        getQueryText={getQueryText}
        databaseId={databaseId}
      />
    </>
  );
};
