export enum DashboardMode {
  Segmentation = "tab-segmentation",
  Funnel = "tab-funnel",
  Sessions = "tab-sessions",
}

export enum DashboardApiEndpoints {
  Events = "/v2/events",
  EventsProperties = "/v2/properties/events",
  EventsFrequentlyUsedProperties = "/v2/properties/events/frequently",
  Cohorts = "/v2/users/cohorts",
  PropertyValues = "/v2/properties/values",
}
