import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import { setSegmentationMeasuredOption } from "metabase/query_builder/actions";
import { getSegmentationMeasuredOption } from "metabase/query_builder/selectors";

import { GroupContainer } from "../components/GroupContainer";

import {
  FormulaTextInput,
  MeasuredControlsContainer,
  MeasuredFormulaContainer,
  StyledApplyButton,
  StyledButton,
  StyledCloseButton,
  StyledMeasuredLink,
  StyledTitle,
  TabsContainer,
} from "./SegmentationMeasuredAsSection.styled";

export const SegmentationMeasuredAsSection = () => {
  const dispatch = useDispatch();

  const measuredOption = useSelector(getSegmentationMeasuredOption);

  const tabsList = [
    {
      value: "Uniques",
      path: "/v2/datasets/funnel/conversion",
    },
    {
      value: "Event Totals",
      path: "/v2/datasets/funnel/over_time",
    },
    {
      value: "Active %",
      path: "/v2/datasets/funnel/conversion",
    },
    {
      value: "Average",
      path: "/v2/datasets/funnel/significance",
    },
  ];

  const setInitialActiveTab = tabsList.findIndex(
    tab => tab.path === measuredOption.path,
  );

  const [activeTab, setActiveTab] = useState(setInitialActiveTab);
  const [isFormulaActive, setIsFormulaActive] = useState(false);
  const [formulaValue, setFormulaValue] = useState("UNIQUES(A)");

  const handleFormulaOpen = () => {
    setIsFormulaActive(true);
  };

  const handleFormulaClose = () => {
    setIsFormulaActive(false);
  };

  useEffect(() => {
    const setInitialActiveTab = tabsList.findIndex(
      tab => tab.path === measuredOption.path,
    );

    setActiveTab(setInitialActiveTab);
  }, [measuredOption.path]);

  return (
    <GroupContainer>
      <>
        <StyledTitle>{"Measuerd As"}</StyledTitle>
        <TabsContainer>
          {tabsList.map((tab, index) => {
            return (
              <StyledButton
                key={index}
                isActive={activeTab === index ? true : false}
                type="button"
                onClick={() => {
                  setActiveTab(index);
                  dispatch(setSegmentationMeasuredOption(tab));
                }}
              >
                {tab.value}
              </StyledButton>
            );
          })}
        </TabsContainer>
        {isFormulaActive && (
          <MeasuredFormulaContainer>
            <FormulaTextInput
              type="text"
              miw={400}
              value={formulaValue}
              onChange={e => setFormulaValue(e.target.value)}
              placeholder={"UNIQUES(A)"}
            />
            <MeasuredControlsContainer>
              <StyledCloseButton
                type="button"
                onClick={() => {
                  handleFormulaClose();
                  setFormulaValue("");
                }}
              >
                Close Formula
              </StyledCloseButton>
              <StyledApplyButton type="button" onClick={handleFormulaClose}>
                Apply Formula
              </StyledApplyButton>
            </MeasuredControlsContainer>
          </MeasuredFormulaContainer>
        )}
        {!isFormulaActive && (
          <StyledMeasuredLink type="button" onClick={handleFormulaOpen}>
            fx Formula
          </StyledMeasuredLink>
        )}
      </>
    </GroupContainer>
  );
};
