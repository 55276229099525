import { Api } from "metabase/api";
import type { DatasetQuery, NativeQueryForm } from "metabase-types/api";

export const dashboardtApi = Api.injectEndpoints({
  endpoints: builder => ({
    getDataset: builder.query<NativeQueryForm, DatasetQuery>({
      query: body => ({
        method: "POST",
        url: "/api/dataset",
        body,
      }),
    }),
  }),
});

const databaseApi = Api.injectEndpoints({
  endpoints: builder => ({
    postEventsNativeQuery: builder.mutation({
      query: body => ({
        url: "/api/dataset",
        method: "POST",
        body,
      }),
    }),
    listDatabaseIdFieldsList: builder.mutation({
      query: ({ id, ...params }) => ({
        method: "GET",
        url: `/api/database/${id}/fields`,
        params,
      }),
    }),
  }),
});

export const {
  usePostEventsNativeQueryMutation,
  useListDatabaseIdFieldsListMutation,
} = databaseApi;

export const { useGetDatasetQuery } = dashboardtApi;
