import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";
import { Icon } from "metabase/ui";

export const StyledModalList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const ModalListItem = styled.li`
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 700;
  padding: 0.5rem 1.5rem;
  padding-right: 1rem;
  color: ${color("text-dark")};
  cursor: pointer;

  &:hover {
    background-color: #eaf5fb;
  }
`;

export const ModalListCheckbox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  color: ${color("brand")};
`;
