import { useState } from "react";

import { useSelector } from "metabase/lib/redux";
import { getTestCohorts } from "metabase/query_builder/selectors";
import { Icon, Modal, Title } from "metabase/ui";

import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
} from "./CohortsModal.styled";

interface IFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  onSave: (cardId: string, properties: string[]) => void;
  cardId?: string;
}

export const CohortsModal = ({
  isOpen,
  onClose,
  additionalCancelOption,
  onSave,
  cardId,
}: IFilterModalProps) => {
  const testCohorts = useSelector(getTestCohorts);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const filteredCheckboxes = testCohorts?.filter(item =>
    item?.display?.toString().toLowerCase().includes(searchValue),
  );

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            Select cohort
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          {testCohorts && (
            <ModalCheckboxes
              onCheckboxChange={cards => setActiveFilters(cards)}
              values={filteredCheckboxes}
            />
          )}
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton
            onClick={() => {
              onSave(cardId, activeFilters);
              onClose();
            }}
          />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
