import { DropdownList } from "../DropdownList";

interface IModalItem {
  title: string;
  options: string[];
  currentOptions?: [];
  isOpen?: boolean;
  onCheckboxChange: (values: string[]) => void;
}

interface IModalDropdownListProps {
  items: IModalItem[];
}

export const ModalDropdownList = ({ items }: IModalDropdownListProps) => {
  return (
    <div>
      {items &&
        items.map((item, index) => {
          return (
            <div key={index}>
              <DropdownList data={item} />
            </div>
          );
        })}
    </div>
  );
};
