import styled from "@emotion/styled";

import { ModalHeader } from "metabase/components/ModalContent";
import { ModalFooter } from "metabase/components/ModalContent";
import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";
import { Modal, NumberInput, Select, TextInput } from "metabase/ui";

export const ModalContent = styled(Modal.Content)`
  max-width: 480px;
`;

export const DashboardModalHeader = styled(ModalHeader)`
  padding-top: 1.5rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid ${color("border")};
`;

export const ModalCloseButton = styled(Modal.CloseButton)`
  color: ${color("text-medium")};
`;

export const DashboardTextInput = styled(TextInput)`
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid ${color("border")};
  margin-right: 0;

  input {
    border-radius: 6px;
    background-color: ${color("bg-light")};
  }
`;

export const EventsModalContent = styled.div`
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.8rem;
  padding-bottom: 1.1rem;
  border-bottom: 1px solid ${color("border")};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    min-height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;

// this selector doesn't work for some reason
export const DashboardModalFooter = styled(ModalFooter)`
  padding-top: 1.5rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-top: 1rem;
`;

export const SaveButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  color: ${color("white")};
  background-color: ${color("brand")};
  border-color: ${color("brand")};
`;

export const CancelButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  color: ${color("text-dark")};
  background-color: ${color("white")};
  border-color: ${color("border")};
`;

export const ModalDescription = styled.p`
  margin: 0;
  font-size: 0.8rem;
  line-height: 0.95rem;
  font-weight: 700;
  color: #a4aab8;
`;

export const ModalInputsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledSelect = styled(Select)`
  width: 120px;
  padding: 0;
  color: inherit;

  input {
    border-color: rgba(80, 158, 227, 0.2);
  }
`;

export const StyledNumberInput = styled(NumberInput)`
  width: 300px;

  input {
    border-radius: 8px;
  }
`;
