import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  resetDashboardData,
  setDashboardMode,
} from "metabase/query_builder/actions";
import { getDashboardMode } from "metabase/query_builder/selectors";
import { Icon } from "metabase/ui";

import {
  SidebarContainer,
  SidebarIconContainer,
  SidebarItem,
  SidebarText,
} from "./VisualizationSidebar.styled";

const tabsData = [
  {
    id: "tab-segmentation",
    value: "Segmentation",
    icon: "pulse",
  },
  {
    id: "tab-funnel",
    value: "Funnel",
    icon: "funnel",
  },
  {
    id: "tab-sessions",
    value: "Sessions",
    icon: "clock",
  },
];

export const VisualizationSidebar = () => {
  const dispatch = useDispatch();
  const dashboardMode = useSelector(getDashboardMode);

  return (
    <SidebarContainer>
      {tabsData.map(tab => {
        return (
          <SidebarItem
            key={tab.id}
            onClick={() => {
              dispatch(setDashboardMode(tab.id));
              // dispatch(resetDashboardData());
            }}
          >
            <SidebarIconContainer active={dashboardMode === tab.id}>
              <Icon name={tab.icon} size={23} />
            </SidebarIconContainer>
            <SidebarText>{tab.value}</SidebarText>
          </SidebarItem>
        );
      })}
    </SidebarContainer>
  );
};
