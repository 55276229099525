import { useEffect, useState } from "react";

import {
  ModalListCheckbox,
  ModalListItem,
  StyledIcon,
  StyledModalList,
} from "./ModalList.styled";

interface IModalListProps {
  items: string[];
  currentOptions?: [];
  isOpen?: boolean;
  isCheckboxMode?: boolean;
  onCheckboxChange: (values: string[]) => void;
}

export const ModalList = ({
  items,
  currentOptions,
  isOpen,
  isCheckboxMode = false,
  onCheckboxChange,
}: IModalListProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleChange = (value: string) => {
    setSelectedOptions(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(item => item !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  useEffect(() => {
    onCheckboxChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (isOpen) {
      const options = currentOptions?.map(option => option?.display);

      setSelectedOptions(options);
    }
  }, [isOpen, currentOptions]);

  return (
    <StyledModalList>
      {items &&
        items.map((item, index) => {
          return (
            <ModalListItem key={index} onClick={() => handleChange(item)}>
              {isCheckboxMode ? (
                <ModalListCheckbox>
                  {selectedOptions.includes(item) && (
                    <StyledIcon name="check" size={20} />
                  )}
                  {item}
                </ModalListCheckbox>
              ) : (
                { item }
              )}
            </ModalListItem>
          );
        })}
    </StyledModalList>
  );
};
