import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";
import { Icon } from "metabase/ui";

export interface ISelectedProps {
  selected?: boolean;
}

export const CardContentWrapper = styled.div`
  min-height: 0;
  max-height: 180px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    min-height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;

export const CardContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid ${color("border")};

  &:last-child {
    border-bottom: none;
  }
`;

export const CardContentColumn = styled.div``;

export const CardContentDeleteButton = styled.button`
  cursor: pointer;
`;

export const CardContentTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  font-size: 0.81rem;
  line-height: 0.95rem;
  font-weight: 500;
  color: ${color("brand")};
  cursor: pointer;
`;

export const CardContentText = styled.button<ISelectedProps>`
  text-align: left;
  margin: 0;
  font-size: 0.81rem;
  line-height: 0.95rem;
  font-weight: 700;
  color: ${props => (props.selected ? color("brand") : color("text-medium"))};
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  color: ${color("text-medium")};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${color("brand")};
`;
