import {
  CardContentColumn,
  CardContentDeleteButton,
  CardContentItem,
  CardContentText,
  CardContentTitle,
  CardContentWrapper,
  IconWrapper,
  StyledIcon,
} from "./CardContent.styled";

import { clearEventGroup } from "metabase/query_builder/actions";

import { DashboardMode } from "../../types/enum";

import { useDispatch, useSelector } from "metabase/lib/redux";

import { Icon } from "metabase/ui";

import {
  getCurrentEvents,
  getDashboardMode,
} from "metabase/query_builder/selectors";

import { removeDoubleQuotes } from "../../utils/removeDoubleQuotes";

interface IPropertyValue {
  value: string;
  display: string;
}

interface IGroupCardData {
  id: string;
  type: string;
  value: string;
  display: string;
  values: IPropertyValue[];
  condition: string;
  selected: string[];
}

interface ISegmentData {
  id: string;
  type: string;
  value: string;
  display: string;
  values: IPropertyValue[];
  condition: string;
  selected: string[];
}

interface ICardContentProps {
  data: IGroupCardData[] | ISegmentData[];
  onParamsClick: (propertyId: string) => void;
  onPropertyDelete: (cardId: string, propertyId: string) => void;
  cardId: string;
}

export const CardContent = ({
  data,
  onParamsClick,
  onPropertyDelete,
  cardId,
}: ICardContentProps) => {
  const dispatch = useDispatch();
  const dashboardMode = useSelector(getDashboardMode);
  const currentEvent = useSelector(getCurrentEvents).find(
    item => item.id === cardId,
  );

  // const currentOptions = new Set([
  //   ...currentGroup.properties,
  //   ...currentGroup.frequentlyUsedProperties,
  // ]);
  const currentOptions =
    currentEvent &&
    new Set([
      ...currentEvent?.groupList?.properties,
      ...currentEvent?.groupList?.frequentlyUsedProperties,
    ]);

  const handleOptionDeleteClick = () => {
    dispatch(
      clearEventGroup({
        eventId: cardId,
        groupData: {
          properties: [],
          frequentlyUsedProperties: [],
        },
      }),
    );
    // dispatch(
    //   updateCurrentGroup({
    //     properties: [],
    //     frequentlyUsedProperties: [],
    //   }),
    // );
  };

  return (
    <CardContentWrapper>
      {data.map((item, index) => {
        return (
          <CardContentItem key={index}>
            <CardContentColumn onClick={() => onParamsClick(item.id)}>
              <CardContentTitle>
                {dashboardMode === DashboardMode.Segmentation && (
                  <IconWrapper>
                    <Icon name="pulse" size={10} color="white" />
                  </IconWrapper>
                )}
                {item.display}
              </CardContentTitle>
              <CardContentText>
                {item.condition}{" "}
                {item?.values?.map(value => {
                  return `${removeDoubleQuotes(value.display)}, `;
                })}
              </CardContentText>
            </CardContentColumn>
            <CardContentColumn>
              <CardContentDeleteButton>
                <StyledIcon
                  name={"close"}
                  size={16}
                  onClick={() => {
                    onPropertyDelete(cardId, item.id);
                  }}
                />
              </CardContentDeleteButton>
            </CardContentColumn>
          </CardContentItem>
        );
      })}
      {currentOptions && currentOptions.size > 0 && (
        <CardContentItem>
          <CardContentColumn>
            <CardContentTitle>Grouped by</CardContentTitle>
            <CardContentText>
              {Array.from(currentOptions).map((value, index) => (
                <p style={{ textAlign: "left" }} key={index}>
                  {value.display},{" "}
                </p>
              ))}
            </CardContentText>
          </CardContentColumn>
          <CardContentColumn>
            <CardContentDeleteButton>
              <StyledIcon
                name={"close"}
                size={16}
                onClick={() => {
                  handleOptionDeleteClick();
                }}
              />
            </CardContentDeleteButton>
          </CardContentColumn>
        </CardContentItem>
      )}
    </CardContentWrapper>
  );
};
