export const mapApiData = (
  keys: string[],
  values: string[][],
): Record<string, string>[] => {
  if (keys && values) {
    if (keys.length === 0 || values.length === 0) {
      return [];
    }

    return values.map(valueArray => {
      const obj: Record<string, string> = {};
      keys.forEach((key, index) => {
        obj[key] = valueArray[index] || "";
      });
      return obj;
    });
  }
};
