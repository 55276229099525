import { useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import { clearGlobalGroup } from "metabase/query_builder/actions";
import {
  getCurrentGroupFilters,
  getCurrentGroupFrequentlyFilters,
} from "metabase/query_builder/selectors";

import { GlobalGroupModal } from "../components/GlobalGroupModal";
import { GroupButton } from "../components/GroupButton/GroupButton";
import { GroupContainer } from "../components/GroupContainer";
import { GroupLink } from "../components/GroupLink";

import {
  CardContentDeleteButton,
  StyledGroupCard,
  StyledIcon,
  StyledText,
  StyledTitle,
} from "./GroupSegmentBySection.styled";

interface IGlobalGroupEndpoints {
  properties: string;
  frequentlyProperties: string;
}

interface IGroupSegmentBySectionProps {
  endpoints: IGlobalGroupEndpoints;
}

export const GroupSegmentBySection = ({
  endpoints,
}: IGroupSegmentBySectionProps) => {
  const dispatch = useDispatch();

  const globalFilters = useSelector(getCurrentGroupFilters);
  const globalFrequentlyFilters = useSelector(getCurrentGroupFrequentlyFilters);

  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const currentGroupList = Array.from(
    new Set([...globalFilters, ...globalFrequentlyFilters]),
  );

  const onGroupModalClose = () => {
    setIsGroupModalOpen(false);
  };

  const onGroupModalOpen = () => {
    setIsGroupModalOpen(true);
  };

  const handleOptionDeleteClick = () => {
    dispatch(clearGlobalGroup());
  };

  return (
    <GroupContainer>
      <>
        <StyledTitle>{"Group Segment by"}</StyledTitle>
        {currentGroupList.length > 0 && (
          <StyledGroupCard>
            {currentGroupList.map((group, index) => {
              return <StyledText key={index}>{group.display}, </StyledText>;
            })}
            <CardContentDeleteButton>
              <StyledIcon
                name={"close"}
                size={16}
                onClick={() => {
                  handleOptionDeleteClick();
                }}
              />
            </CardContentDeleteButton>
          </StyledGroupCard>
        )}

        {currentGroupList.length > 0 ? (
          <GroupLink name={"Select user property"} onClick={onGroupModalOpen} />
        ) : (
          <GroupButton
            name={"Select user property"}
            onClick={onGroupModalOpen}
          />
        )}
        <GlobalGroupModal
          title={"Group by"}
          isOpen={isGroupModalOpen}
          onClose={onGroupModalClose}
          endpoints={endpoints}
        />
      </>
    </GroupContainer>
  );
};
