import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setCurrentProperties,
  setSegmentProperties,
} from "metabase/query_builder/actions";
import {
  getCurrentSegments,
  getSegmentPropertiesList,
} from "metabase/query_builder/selectors";
import { Icon, Modal, Title } from "metabase/ui";
import { NativeDatasetQuery } from "metabase-types/api";

import {
  useGetDatasetQuery,
  usePostEventsNativeQueryMutation,
} from "../../api/dashboardApi";
import { IColsItem } from "../../types/interfaces";

import { mapApiData } from "../../utils/mapApiData";
import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
} from "./SegmentFilterModal.styled";

interface IEventProperty {
  id: string;
  type: string;
  value: string;
  display: string;
}

interface IFilterData {
  title: string;
  options: IEventProperty[];
}

interface ISegmentsEndpoints {
  filters: string;
  propertyValues: string;
  cohorts: string;
}

interface IFilterModalProps {
  data: IFilterData;
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  onSave: (cardId: string, properties: string[]) => void;
  cardId?: string;
  endpoints: ISegmentsEndpoints;
}

export const SegmentFilterModal = ({
  data,
  isOpen,
  onClose,
  additionalCancelOption,
  onSave,
  cardId,
  endpoints,
}: IFilterModalProps) => {
  const [postNativeQuery] = usePostEventsNativeQueryMutation();
  const dispatch = useDispatch();
  const currentSegments = useSelector(getCurrentSegments);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);

  const payloadPropertiesQuery = `{
    "path": "${endpoints.filters}",
    "method": "post",
    "body": {},
    "headers": {}
  }`;

  const nativePropertiesQuery: NativeDatasetQuery = {
    native: {
      query: payloadPropertiesQuery,
    },
    type: "native",
    database: 2,
  };

  const propertiesData = useGetDatasetQuery(nativePropertiesQuery);

  const segmentProperties = useSelector(getSegmentPropertiesList);

  const currentPropertiesList = currentSegments?.find(
    item => item.id === cardId,
  )?.filters;

  const formattedKeys = segmentProperties?.data?.data?.cols?.map(
    col => col.name,
  );
  const formattedValues = segmentProperties?.data?.data?.rows;

  const eventsData = mapApiData(formattedKeys, formattedValues);

  const filteredPropertiesNew = eventsData?.filter(item =>
    item?.display?.toString().toLowerCase().includes(searchValue),
  );

  const fetchTestProperties = () => {
    dispatch(setSegmentProperties(propertiesData));

    const formattedKeys = propertiesData?.data?.data?.cols?.map(
      col => col.name,
    );
    const formattedValues = propertiesData?.data?.data?.rows;

    const eventsData = mapApiData(formattedKeys, formattedValues);
    dispatch(setCurrentProperties({ eventId: cardId, properties: eventsData }));
  };

  useEffect(() => {
    if (propertiesData.data !== null) {
      fetchTestProperties();
    }
  }, [propertiesData.data]);

  const fetchFiltersData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.filters}",
      "method": "post",
      "body": {},
      "headers": {}
    }`;

    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };

    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(
        (col: IColsItem) => col.name,
      );
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(
        setCurrentProperties({ eventId: cardId, properties: eventsData }),
      );
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  // useEffect(() => {
  //   fetchFiltersData();
  // }, []);

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            {data.title}
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          <ModalCheckboxes
            onCheckboxChange={cards => setActiveFilters(cards)}
            values={filteredPropertiesNew}
            currentProperties={currentPropertiesList}
            isOpen={isOpen}
          />
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton
            onClick={() => {
              onSave(cardId, activeFilters);
              onClose();
            }}
          />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
