import { ICohortItem, IFilterItem } from "../../types/interfaces";
import { removeDoubleQuotes } from "../../utils/removeDoubleQuotes";

import {
  CardContentColumn,
  CardContentDeleteButton,
  CardContentItem,
  CardContentText,
  CardContentTitle,
  CardContentWrapper,
  StyledIcon,
} from "./CohortCardContent.styled";

interface ICardContentProps {
  data: ICohortItem[];
  onParamsClick: (propertyId: string) => void;
  onCohortClick: () => void;
  onCohortDelete: (cardId: string, propertyId: string) => void;
  onFilterDelete: (cardId: string, propertyId: string) => void;
  cardId: string;
  filters: IFilterItem[];
}

export const CohortCardContent = ({
  data,
  onParamsClick,
  onCohortClick,
  onCohortDelete,
  onFilterDelete,
  cardId,
  filters,
}: ICardContentProps) => {
  return (
    <CardContentWrapper>
      {filters &&
        filters.map(filter => {
          return (
            <CardContentItem key={filter.id}>
              <CardContentColumn onClick={() => onParamsClick(filter.id)}>
                <CardContentTitle>{filter.display}</CardContentTitle>
                {filter.condition}{" "}
                {filter?.values?.map(value => {
                  return `${removeDoubleQuotes(value.display)}, `;
                })}
              </CardContentColumn>
              <CardContentColumn>
                <CardContentDeleteButton>
                  <StyledIcon
                    name={"close"}
                    size={16}
                    onClick={() => {
                      onFilterDelete(cardId, filter.id);
                    }}
                  />
                </CardContentDeleteButton>
              </CardContentColumn>
            </CardContentItem>
          );
        })}

      {data.map((item, index) => {
        return (
          <CardContentItem key={index}>
            <CardContentColumn onClick={() => onCohortClick()}>
              <CardContentTitle>Cohorts</CardContentTitle>
              <CardContentText>
                {item.cohortsList.map(item => (
                  <p key={item.id}>{item.display}, </p>
                ))}
              </CardContentText>
            </CardContentColumn>
            <CardContentColumn>
              <CardContentDeleteButton>
                <StyledIcon
                  name={"close"}
                  size={16}
                  onClick={() => {
                    onCohortDelete(cardId, item.id);
                  }}
                />
              </CardContentDeleteButton>
            </CardContentColumn>
          </CardContentItem>
        );
      })}
    </CardContentWrapper>
  );
};
