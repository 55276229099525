import { useState } from "react";

import { Modal, Title } from "metabase/ui";

import { CancelButton } from "../CancelButton";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  ModalCloseButton,
  ModalContent,
} from "./AddSegmentsModal.styled";

interface IAddTaskModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (cardId: string) => void;
  additionalCancelOption?: boolean;
}

export const AddSegmentsModal = ({
  title,
  isOpen,
  onClose,
  onSave,
  additionalCancelOption,
}: IAddTaskModalProps) => {
  const [segmentName, setSegmentName] = useState("");

  return (
    <Modal.Root opened={isOpen} onClose={() => setSegmentName("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            {title}
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSegmentName("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="text"
          miw={400}
          value={segmentName}
          onChange={e => setSegmentName(e.target.value)}
          placeholder={"Segment's name…"}
        />
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton
            onClick={() => {
              onSave(segmentName);
              setSegmentName("");
              onClose();
            }}
          />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
