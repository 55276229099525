import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  resetDashboardData,
  restoreDashboardData,
} from "metabase/query_builder/actions";
import {
  getBaselineSegment,
  getCurrentEvents,
  getCurrentEventsOrder,
  getCurrentSegments,
  getGroupList,
  getMeasuredCompleted,
  getMeasuredOption,
  getSignificanceLevel,
} from "metabase/query_builder/selectors";

import { EventsSection } from "../EventsSection";
import { GroupSegmentBySection } from "../GroupSegmentBySection";
import { MeasuredAsSection } from "../MeasuredAsSection";
import { SegmentBySection } from "../SegmentBySection";
import { useListDatabaseIdFieldsListMutation } from "../api/dashboardApi";
import { DashboardApiEndpoints } from "../types/enum";

interface ISessionDashboardProps {
  // Исправить типы
  updateQuery: any;
  getQueryText: any;
  databaseId: number;
}

export const SessionDashboard = ({
  updateQuery,
  getQueryText,
  databaseId,
}: ISessionDashboardProps) => {
  const [listDatabaseIdFieldsList] = useListDatabaseIdFieldsListMutation();
  const dispatch = useDispatch();

  const currentEvents = useSelector(getCurrentEvents);
  const currentGroup = useSelector(getGroupList);
  const currentEventsOrder = useSelector(getCurrentEventsOrder);
  const measuredCompleted = useSelector(getMeasuredCompleted);
  const measuredOption = useSelector(getMeasuredOption);
  const currentSegments = useSelector(getCurrentSegments);
  const significanceLevel = useSelector(getSignificanceLevel);
  const baselineSegment = useSelector(getBaselineSegment);

  const [dataFieldId, setDataFieldId] = useState();

  const SessionGroupEndpoints = {
    properties: DashboardApiEndpoints.EventsProperties,
    frequentlyProperties: DashboardApiEndpoints.EventsFrequentlyUsedProperties,
  };

  const SessionSegmentsEndpoints = {
    filters: DashboardApiEndpoints.EventsProperties,
    propertyValues: DashboardApiEndpoints.PropertyValues,
    cohorts: DashboardApiEndpoints.Cohorts,
  };

  const SessionEventsEndpoints = {
    events: DashboardApiEndpoints.Events,
    properties: DashboardApiEndpoints.EventsProperties,
    frequentlyProperties: DashboardApiEndpoints.EventsFrequentlyUsedProperties,
    propertyValues: DashboardApiEndpoints.PropertyValues,
  };

  const fetchDatabaseId = async () => {
    try {
      const response = await listDatabaseIdFieldsList({
        id: databaseId,
      }).unwrap();
      const id = response.find(item => {
        return item.name === "date_partition";
      }).id;

      setDataFieldId(id);
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  useEffect(() => {
    updateQuery(
      measuredOption.path,
      measuredCompleted.value,
      measuredCompleted.overtime,
      currentEvents,
      currentSegments,
      currentGroup,
      currentEventsOrder,
      significanceLevel,
      baselineSegment,
      dataFieldId,
    );
  }, [
    currentEvents,
    measuredOption,
    measuredCompleted,
    currentSegments,
    currentGroup,
    currentEventsOrder,
    significanceLevel,
    baselineSegment,
    dataFieldId,
  ]);

  useEffect(() => {
    const queryText = getQueryText();

    console.log(queryText);

    if (queryText) {
      const queryData = JSON.parse(queryText);

      const measuredData = {
        amount: queryData.body.conversion_seconds / 86400,
        value: queryData.body.conversion_seconds,
      };

      const dashboardData = {
        measured: {
          type: "days",
          amount: measuredData.amount,
          value: measuredData.value,
          overtime: queryData.body.over_time,
          path: queryData.path,
        },
        eventsOrder: {
          id: 1,
          value: "In this order",
          label: "In this order",
          valueId: "this_order",
        },
        baseline: queryData.body.baseline_segment_number,
        significance: queryData.body.significance_level,
        currentGroup: queryData.body.group_by,
        segments: queryData.body.segments,
        events: queryData.body.events,
      };
      console.log(dashboardData);

      dispatch(restoreDashboardData(dashboardData));
    }
  }, []);

  useEffect(() => {
    fetchDatabaseId();

    // return () => {
    //   dispatch(resetDashboardData());
    // };
  }, []);
  return (
    <>
      <EventsSection
        updateQuery={updateQuery}
        getQueryText={getQueryText}
        databaseId={databaseId}
        endpoints={SessionEventsEndpoints}
      />
      <MeasuredAsSection />
      <SegmentBySection endpoints={SessionSegmentsEndpoints} />
      <GroupSegmentBySection endpoints={SessionGroupEndpoints} />
    </>
  );
};
