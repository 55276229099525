import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";
import { Icon, Title } from "metabase/ui";

export const StyledTitle = styled(Title)`
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 900;
  margin-bottom: 16px;
`;

export const StyledText = styled.p`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const StyledGroupCard = styled.div`
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  width: 304px;
  border: 1px solid ${color("border")};
  border-radius: 8px;
  background-color: ${color("white")};
`;

export const CardContentDeleteButton = styled.button`
  position: absolute;
  right: 10px;
  top: 1rem;
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  color: ${color("text-medium")};
`;
