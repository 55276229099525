import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setGlobalFilters,
  setGlobalFrequentlyFilters,
  updateCurrentGroup,
} from "metabase/query_builder/actions";
import {
  getCurrentGroupFilters,
  getCurrentGroupFrequentlyFilters,
  getGlobalFilters,
  getGlobalFrequentlyFilters,
} from "metabase/query_builder/selectors";
import { Icon, Modal } from "metabase/ui";
import { NativeDatasetQuery } from "metabase-types/api";

import { usePostEventsNativeQueryMutation } from "../../api/dashboardApi";
import { IColsItem } from "../../types/interfaces";
import { mapApiData } from "../../utils/mapApiData";
import { CancelButton } from "../CancelButton";
import { ModalDropdownList } from "../ModalDropdownList";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
  StyledTitle,
} from "./GlobalGroupModal.styled";

interface IGlobalGroupEndpoints {
  properties: string;
  frequentlyProperties: string;
}

interface IFilterItem {
  id: string;
  name: string;
  subname: string;
  type: string;
  display: string;
}

interface IGroupModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  endpoints: IGlobalGroupEndpoints;
}

export const GlobalGroupModal = ({
  title,
  isOpen,
  onClose,
  additionalCancelOption,
  endpoints,
}: IGroupModalProps) => {
  const [postNativeQuery] = usePostEventsNativeQueryMutation();
  const dispatch = useDispatch();

  const globalFilters = useSelector(getGlobalFilters);
  const globalFrequentlyFilters = useSelector(getGlobalFrequentlyFilters);
  const currentGroupFilters = useSelector(getCurrentGroupFilters);
  const currentGroupFrequentlyFilters = useSelector(
    getCurrentGroupFrequentlyFilters,
  );

  const [searchValue, setSearchValue] = useState<string>("");
  const [frequentlyPropertiesState, setFrequentlyPropertiesState] = useState<
    string[]
  >([]);
  const [eventpropertiesState, eventPropertiesState] = useState<string[]>([]);

  const filtersValues = globalFilters?.map(
    (item: IFilterItem) => item?.display,
  );
  const frequentlyUsedValues = globalFrequentlyFilters?.map(
    (item: IFilterItem) => item?.display,
  );
  const filteredProperties = filtersValues?.filter((item: IFilterItem) =>
    item?.toString().toLowerCase().includes(searchValue),
  );
  const filteredFrequentlyUsed = frequentlyUsedValues?.filter(
    (item: IFilterItem) => item?.toString().toLowerCase().includes(searchValue),
  );

  const handleSaveButtonClick = () => {
    const currentProperties = globalFilters.filter((item: IFilterItem) =>
      eventpropertiesState.includes(item.display),
    );
    const currentFrequentlyProperties = globalFrequentlyFilters.filter(
      (item: IFilterItem) => frequentlyPropertiesState.includes(item.display),
    );

    dispatch(
      updateCurrentGroup({
        properties: currentProperties,
        frequentlyUsedProperties: currentFrequentlyProperties,
      }),
    );
    setSearchValue("");
    onClose();
  };

  const handleFrequentlyPropertiesChange = (values: string[]) => {
    setFrequentlyPropertiesState(values);
  };

  const handleEventPopertiesChange = (values: string[]) => {
    eventPropertiesState(values);
  };

  const fetchFiltersData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.properties}",
      "method": "post",
      "body": {},
      "headers": {}
    }`;

    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };

    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(
        (col: IColsItem) => col.name,
      );
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(setGlobalFilters(eventsData));
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  const fetchFrequentlyFiltersData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.frequentlyProperties}",
      "method": "post",
      "body": {},
      "headers": {}
    }`;

    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };

    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(
        (col: IColsItem) => col.name,
      );
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(setGlobalFrequentlyFilters(eventsData));
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  useEffect(() => {
    fetchFiltersData();
    fetchFrequentlyFiltersData();
  }, []);

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <StyledTitle>{title}</StyledTitle>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          <ModalDropdownList
            items={[
              {
                title: "Frequently Used Properties",
                options: filteredFrequentlyUsed,
                currentOptions: currentGroupFrequentlyFilters,
                isOpen: isOpen,
                onCheckboxChange: handleFrequentlyPropertiesChange,
              },
              {
                title: "User Properties",
                options: filteredProperties,
                currentOptions: currentGroupFilters,
                isOpen: isOpen,
                onCheckboxChange: handleEventPopertiesChange,
              },
            ]}
          />
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton onClick={handleSaveButtonClick} />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
