import styled from "@emotion/styled";

import { Title } from "metabase/ui";

export const StyledTitle = styled(Title)`
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 900;
  margin-bottom: 16px;
`;
