import styled from "@emotion/styled";

import { ModalHeader } from "metabase/components/ModalContent";
import { ModalFooter } from "metabase/components/ModalContent";
import { color } from "metabase/lib/colors";
import { Icon, Modal, TextInput } from "metabase/ui";

interface IModalListItemProps {
  isActive?: boolean;
}

export const ModalContent = styled(Modal.Content)`
  max-width: 560px;
`;

export const DashboardModalHeader = styled(ModalHeader)`
  padding-top: 1.5rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid ${color("border")};
`;

export const ModalCloseButton = styled(Modal.CloseButton)`
  color: ${color("text-medium")};
`;

export const DashboardTextInput = styled(TextInput)`
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid ${color("border")};
  margin-right: 0;

  input {
    border-radius: 6px;
    background-color: ${color("bg-light")};
  }
`;

// this selector doesn't work for some reason
export const DashboardModalFooter = styled(ModalFooter)`
  padding-top: 1.5rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-top: 1rem;
`;

export const ModalListCheckbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  color: ${color("brand")};
`;
