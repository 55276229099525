import { useState } from "react";

import { Modal, Title } from "metabase/ui";

import {
  CancelButton,
  DashboardModalFooter,
  DashboardModalHeader,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
  ModalDescription,
  ModalInputsContainer,
  SaveButton,
  StyledNumberInput,
  StyledSelect,
} from "./SelectModal.styled";

interface ISelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  values: string[];
  onSelect: (amount: number, type: string, value: number) => void;
}

export const SelectModal = ({
  isOpen,
  onClose,
  values,
  onSelect,
}: ISelectModalProps) => {
  const [selectState, setSelectState] = useState<string>("days");
  const [numberState, setNumberState] = useState<number>(1);

  const handleSaveButton = (amount: number, type: string, value: number) => {
    onSelect(amount, type, value);
    onClose();
  };

  const calculateSecondsAmount = (stateValue: string) => {
    let result = 0;

    switch (stateValue) {
      case "days":
        result = numberState * 86400;
        break;
      case "hours":
        result = numberState * 3600;
        break;
      case "minutes":
        result = numberState * 60;
        break;
      case "seconds":
        result = numberState;
        break;
      default: // или любое другое значение по умолчанию, если требуется
        result = 0;
        break;
    }

    return result;
  };

  return (
    <Modal.Root opened={isOpen} onClose={() => console.log("close")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1.5rem" lh="1.6rem" color="inherit">
            {"Completed within"}
          </Title>
          <ModalCloseButton onClick={onClose} />
        </DashboardModalHeader>
        <EventsModalContent>
          <ModalInputsContainer>
            <StyledNumberInput
              value={numberState}
              min={1}
              defaultValue={1}
              onChange={(value: number) => setNumberState(value)}
            />
            <StyledSelect
              p={12}
              value={selectState}
              data={values}
              onChange={(value: string) => setSelectState(value)}
              styles={{
                dropdown: {
                  maxHeight: 100,
                  padding: "10px",
                  overflowY: "auto",
                  color: "white",
                  backgroundColor: "white",
                },
                root: {
                  padding: "0.1rem",
                },
                input: {
                  borderRadius: "8px",
                  padding: "12px",
                },
                item: {
                  background: "transparent",
                  borderColor: "transparent",
                  marginBottom: "0",
                },
                label: {
                  background: "white",
                },
                icon: {
                  color: "#509EE3",
                  fill: "#509EE3",
                  width: "12x",
                  height: "12px",
                },
                rightSection: {
                  color: "#509EE3",
                  fill: "#509EE3",
                },
              }}
            />
          </ModalInputsContainer>
          <ModalDescription>
            Time to convert is calculated using the first completion event.
          </ModalDescription>
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          <CancelButton type="button" onClick={() => onClose()}>
            {"Cancel"}
          </CancelButton>
          <SaveButton
            type="button"
            onClick={() => {
              const typeNumber = calculateSecondsAmount(selectState);

              handleSaveButton(numberState, selectState, typeNumber);
            }}
          >
            {"Save"}
          </SaveButton>
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
